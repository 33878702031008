import React from "react"
import { createGlobalStyle } from "styled-components"

export default function FontSchemeManagement({ fontScheme, children }) {
  const GlobalStyles = createGlobalStyle`
/* 
 @font-face {
   font-family: ${fontScheme.defaultFontFamily};
   src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
    

} */
//  * {
//    font-family: ${fontScheme.defaultFontFamily};
//    font-display: swap;
//  }
  html {
    --color-text: black;
    --color-background: white;
    --color-primary: rebeccapurple;
    
  }
  h1 {
    font-size: ${fontScheme.h1FontSize}px;
    color: #${fontScheme.h1FontColor};
    font-family: ${fontScheme.h1FontFamily};
  }
  h2 {
  font-size: ${fontScheme.h2FontSize}px;
    color: #${fontScheme.h2FontColor};
    font-family: ${fontScheme.h2FontFamily};
  }
  h3 {
  font-size: ${fontScheme.h3FontSize}px;
    color: #${fontScheme.h3FontColor};
    font-family: ${fontScheme.h3FontFamily};
  }
  h4 {
  font-size: ${fontScheme.h4FontSize}px;
    color: #${fontScheme.h4FontColor};
    font-family: ${fontScheme.h4FontFamily};
  }
  h5 {
  font-size: ${fontScheme.h5FontSize}px;
    color: #${fontScheme.h5FontColor};
    font-family: ${fontScheme.h5FontFamily};
  }

  .errorInput {
    border: 1px solid red !important;
  }
`
  return (
    <React.Fragment>
      <GlobalStyles />
      {children}
    </React.Fragment>
  )
}
